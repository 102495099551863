import { AnchorHTMLAttributes, DetailedHTMLProps, ReactNode, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";
import styles from "./index.module.css";
import { isUrl } from "@/utils/validate";

type BaseLinkProps = {
  className?: string;
  locale?: string;
  children?: ReactNode;
  href?: string;
  onBeforeNavigate?: (path: string) => void; // 新增的回调属性
} & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export default function Link({ children, href, locale, onBeforeNavigate, ...props }: BaseLinkProps) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      if (onBeforeNavigate) {
        onBeforeNavigate(url);
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [onBeforeNavigate, router.events]);


  const getLocale = useMemo(() => {
    // 如果router.defaultLocale和locale相同，返回空字符串
    if (router.defaultLocale === locale) {
      return '';
    }
    if (!locale) return ''
    return `/${locale}` || '';
  }, [locale]);

  const getLocaleHref = useMemo(() => {
    if (!href) return '';
    const path = href.replace(/^\/(rare-disease2-web)\//, '/')
    // 如果 href 中存在 /en/ 或 /zh/，直接返回 href
    if (/\/(en|zh)\//.test(path)) {
      return path.replace(/^\/(en|zh)\//, `/${locale}/`);
    }

    // 否则使用正则替换为 getLocale
    return `${getLocale}${path}`;
  }, [href, getLocale, locale]);

  // 如果href是url，直接返回href，否则返回拼接后的href
  const getHerf = useMemo(() => {
    if (!href) return '';
    if (isUrl(href)) return href;

    // 如果是测试环境，拼接'/rare-disease2-web' 并且拼接locale 和 href
    if (process.env.NEXT_PUBLIC_DOMAIN_ENV === 'test' && !getLocaleHref.startsWith('/rare-disease2-web')) {
      return `${process.env.NEXT_PUBLIC_DOMAIN_ENV === 'test' ? '/rare-disease2-web' : ''}${getLocaleHref}`;
    }
    return getLocaleHref;
  }, [href, getLocaleHref]);

  return (
    <a
      {...props}
      href={getHerf}
      className={classNames(styles['base'], props.className)}
    >
      {children}
    </a>
  );

}