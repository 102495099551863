import React, { forwardRef } from "react";
import { TooltipProps as BaseTooltipProps, Tooltip as BaseTooltip } from "antd";
import styles from "./tooltip.module.css";
import Image from "@/components/image";
import { ImageProps } from "next/image";
import xiaochengxuQrcode from "@/public/assets/images/xiaochengxu.jpg";

type TooltipProps = {
  children: React.ReactNode;
  miniProgramCode: ImageProps["src"];
  miniProgramTitle: string;
  miniProgramContent?: string | React.ReactNode;
  placement: BaseTooltipProps["placement"];
} & Omit<BaseTooltipProps, "title" | "placement">;

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const renderTitle = () => (
    <div className={styles.tooltip__wrapper}>
      {props.miniProgramTitle ? <h3>{props.miniProgramTitle}</h3> : null}
      <Image
        src={props.miniProgramCode || xiaochengxuQrcode}
        alt={props.miniProgramTitle}
        width={150}
        height={150}
        className={styles.qrcode}
      />
      {props.miniProgramContent ? <p>{props.miniProgramContent}</p> : null}
    </div>
  );

  return (
    <BaseTooltip
      placement={props.placement || "right"}
      color={"#ffffff"}
      title={renderTitle}
      overlayStyle={{
        width: "250px",
        maxWidth: "250px",
      }}
      overlayInnerStyle={{
        color: "var(--color-font-black)",
      }}
      zIndex={999}
      arrow={false}
    >
      <div ref={ref}>
        {props.children}
      </div>
    </BaseTooltip>
  );
});

Tooltip.displayName = "Tooltip";

export default Tooltip;