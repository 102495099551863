import React, { FC } from "react";
import Tooltip from "@/components/tooltip";
import styles from "./index.module.css";
import type { StaticImageData } from "next/image";

interface FooterTooltipGroupProps {
  title: string;
  tooltips: { code: StaticImageData; title: string; content: string }[];
}
/**
 * @description: 页脚浮动层组
 * @param {*} param1
 * @return {*}
 */
const FooterTooltipGroup: FC<FooterTooltipGroupProps> = ({ title, tooltips }) => (
  <li className={styles.page_foot__link_item}>
    <h3>{title}</h3>
    <div className={styles.items_wrapper}>
      {tooltips.map((tooltip, index) => (
        <Tooltip
          key={index}
          miniProgramCode={tooltip.code}
          miniProgramTitle={tooltip.title}
          miniProgramContent={tooltip.content}
          placement="right"
        >
          <div className={styles.qrcode_item}>
            <p>{tooltip.title}</p>
          </div>
        </Tooltip>
      ))}
    </div>
  </li>
);

export default FooterTooltipGroup;