import React, { FC } from "react";
import Link from "@/components/link";
import styles from "./index.module.css";

interface FooterLinkGroupProps {
  title: string;
  links: { href: string; text: string }[];
}
/**
 * @description: 页脚链接组
 * @param {*} param1
 * @return {*}
 */
const FooterLinkGroup: FC<FooterLinkGroupProps> = ({ title, links }) => (
  <li className={styles.page_foot__link_item}>
    <h3>{title}</h3>
    <div className={styles.items_wrapper}>
      {links.map((link, index) => (
        <Link key={index} className={styles.link} href={link.href}>
          {link.text}
        </Link>
      ))}
    </div>
  </li>
);

export default FooterLinkGroup;