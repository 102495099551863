export const isObject = (value: unknown): value is Record<any, any> =>
  value !== null && typeof value === "object";
export const isFunction = (value: unknown): value is (...args: any) => any =>
  typeof value === "function";

export const isString = (value: unknown): value is string =>
  typeof value === "string";
export const isBoolean = (value: unknown): value is boolean =>
  typeof value === "boolean";
export const isNumber = (value: unknown): value is number =>
  typeof value === "number";
export const isUndef = (value: unknown): value is undefined =>
  typeof value === "undefined";

/**
 * @description: uuidv4
 * @return {string} 返回uuidv4字符串
 * @author DXY
 * @update 2021-10-11
 * @example uuidv4() => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
 */
export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * @description: 根据value找label
 * @param list - Option数组
 * @param value - 需要查找的value
 * @returns 返回查找到的label
 * @author DXY
 * @update 2021-10-11
 * @example getLabelByValue([{ label: '男', value: 1 }, { label: '女', value: 2 }], 1) => '男'
 */
export const getLabelByValue = (
  list: Option<string | number | boolean>[],
  value: string | number | boolean
): string => {
  const item = list.find((n) => n.value === value);
  return item?.label ?? '';
};